import logo from "./logo.svg";
import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import CircularProgress from "@mui/material/CircularProgress";
import { useTime } from "react-timer-hook";
import moment from "moment";
import "./App.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "08:00:00 - 09:00:00",
  "09:00:00 - 10:00:00",
  "10:00:00 - 11:00:00",
  "11:00:00 - 12:00:00",
  "12:00:00 - 13:00:00",
  "13:00:00 - 14:00:00",
  "14:00:00 - 15:00:00",
  "15:00:00 - 16:00:00",
  "16:00:00 - 17:00:00",
  "17:00:00 - 18:00:00",
  "18:00:00 - 19:00:00",
  "19:00:00 - 20:00:00",
  "20:00:00 - 21:00:00",
  "21:00:00 - 22:00:00",
  "22:00:00 - 23:00:00",
  "23:00:00 - 00:00:00",
];
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
function App() {
  const theme = useTheme();
  const [loading, setLoading] = React.useState(false);
  const [slot, setSlot] = React.useState("");
  const [multiSlot, setMultiSlot] = React.useState([]);
  const [multiSpace, setMultiSpace] = React.useState([]);
  const [tokenValidated, setTokenValidated] = React.useState(false);
  const [token, setToken] = React.useState("");
  const [showToken, setShowToken] = React.useState(false);
  const [bookingSucess, setBookingSucess] = React.useState(false);
  const [bookingSucessMessage, setBookingSucessMessage] = React.useState("");
  const [bookingFailedMessage, setBookingFailedMessage] = React.useState([""]);
  const [bookingMessage, setBookingMessage] = React.useState([]);
  const [validationMessage, setValidationMessage] = React.useState("");
  const [bookingData, setBookingData] = React.useState(null);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [space, setSpace] = React.useState("");
  const { seconds, minutes, hours, ampm } = useTime({ format: "24-hour" });
  //let bookingMessage = [];

  React.useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      validateToken(localStorage.getItem("token"));
    }
    if (localStorage.getItem("name") !== null) {
      setName(localStorage.getItem("name"));
    }
    if (localStorage.getItem("email") !== null) {
      setEmail(localStorage.getItem("email"));
    }
    if (localStorage.getItem("space") !== null) {
      setSpace(localStorage.getItem("space"));
    }
    if (localStorage.getItem("slot") !== null) {
      setSlot(localStorage.getItem("slot"));
    }
    // if (localStorage.getItem("multislot") !== null) {
    //   setMultiSlot(localStorage.getItem("multislot"));
    // }
    // if (localStorage.getItem("multispace") !== null) {
    //   setMultiSpace(localStorage.getItem("multispace"));
    // }
  }, []);

  const validateToken = (xToken) => {
    setLoading(true);
    if (xToken === "") return;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: xToken,
      }),
    };

    fetch("https://my.hdc.com.mv/api/Token/validate", requestOptions).then(
      (response) => {
        response.json().then((data) => {
          if (data.isSuccess) {
            setTokenValidated(true);
            setToken(xToken);
            localStorage.setItem("token", xToken);
          } else {
            setTokenValidated(false);
          }
          setLoading(false);
        });
      }
    );
  };

  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setSlot(
  //     // On autofill we get a the stringified value.
  //     typeof value === "string" ? value.split(",") : value
  //   );
  // };
  const handleChange = (event) => {
    setSlot(event.target.value);
  };
  const handleChangeMultiSlot = (event) => {
    setMultiSlot(event.target.value);
  };
  const handleChangeMultiSpace = (event) => {
    setMultiSpace(event.target.value);
  };
  const handleChangeSpace = (event) => {
    setSpace(event.target.value);
  };

  const booking = async (
    bookingEmail,
    bookingName,
    bookingDate,
    bookingStartTime,
    bookingEndTime,
    bookingSpace
  ) => {
    setLoading(true);
    //const tomorrow = new Date(today).getDate() + 1;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        customerEmail: bookingEmail,
        customerName: bookingName,
        date: bookingDate,
        fromTime: bookingStartTime,
        toTime: bookingEndTime,
        spaceId: bookingSpace,
      }),
    };

    fetch(
      "https://ems-web-api-production.azurewebsites.net/Bookings",
      requestOptions
    ).then((response) => {
      response.json().then((data) => {
        //console.log("data.isSuccess: ", data);
        if (data["isSuccess"] === true) {
          // console.log("data.isSuccess Message: ", data["message"]);
          //  console.log("data.isSuccess: ", data.isSuccess);
          setBookingSucess(true);
          localStorage.setItem("email", email);
          localStorage.setItem("name", name);
          localStorage.setItem("slot", slot);
          localStorage.setItem("multislot", JSON.stringify(multiSlot));
          localStorage.setItem("space", space);
          localStorage.setItem("multispace", JSON.stringify(multiSpace));
          setBookingSucessMessage(data["message"]);
        } else {
          setBookingFailedMessage(data["message"]);
        }
        //setBookingMessage(data["message"]);
        // console.log("slot", slot, "space:", space, "message:", data["message"]);
        setBookingMessage((oldValue) => [
          ...oldValue,
          [
            "slot:" +
              bookingStartTime +
              " space:" +
              bookingSpace +
              " message:" +
              data["message"],
          ],
        ]);

        setBookingData(JSON.stringify(data));
      });
      setLoading(false);
    });
  };

  const onSubmit = (event) => {
    //event.preventDefault();
    setValidationMessage("");
    setBookingSucess(false);
    setBookingSucessMessage("");
    setBookingFailedMessage("");
    setBookingMessage([]);

    // bookingMessage.length = 0;
    //setBookingMessage("");

    //setLoading(true);
    if (name === "") {
      setValidationMessage("Please enter your name");
      setLoading(false);
      return;
    }
    if (email === "") {
      setValidationMessage("Please enter your email");
      setLoading(false);
      return;
    }
    if (multiSlot.length === 0) {
      setValidationMessage("Please select your slot");
      setLoading(false);
      return;
    }
    if (multiSpace.length === 0) {
      setValidationMessage("Please select your space");
      setLoading(false);
      return;
    }

    if (multiSlot.length > 0) {
      for (let i = 0; i < multiSlot.length; i++) {
        //  console.log("multiSlot[i]: ", multiSlot[i]);
        if (multiSpace.length > 0) {
          for (let j = 0; j < multiSpace.length; j++) {
            //console.log("multiSpace[j]: ", multiSpace[j]);
            booking(
              email,
              name,
              moment(new Date()).add(1, "days").format("DD/MM/YYYY"),
              multiSlot[i].split("-")[0].trim(),
              multiSlot[i].split("-")[1].trim(),
              multiSpace[j]
            );
          }
        }
        // booking(
        //   email,
        //   name,
        //   moment(new Date()).add(1, "days").format("DD/MM/YYYY"),
        //   multiSlot,
        //   multiSpace
        // );
      }
    }
  };
  //console.log("bookingMessage: ", bookingMessage);

  return (
    <div className="App">
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
        }}
      >
        <Paper
          sx={{
            height: "100%",
            padding: 5,
          }}
        >
          <h1>oBot</h1>

          <Stack spacing={2}>
            <h1 style={{}}>
              <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
              {/* <span> {ampm}</span> */}
            </h1>
            <TextField
              id="outlined-basic"
              label="Name"
              value={name}
              variant="outlined"
              onChange={(e) => setName(e.target.value)}
              required
            />
            <TextField
              id="outlined-basic"
              label="Email"
              value={email}
              variant="outlined"
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <Stack spacing={2} direction="row">
              <Button variant="text" onClick={() => setShowToken(!showToken)}>
                Authentication
              </Button>
              {tokenValidated ? (
                <Chip label="OK" color="success" />
              ) : (
                <Chip label="ERROR" color="error" />
              )}
            </Stack>
            {showToken && (
              <TextField
                id="outlined-basic"
                label="Token"
                variant="outlined"
                defaultValue={token}
                multiline
                onChange={(e) => {
                  validateToken(e.target.value);
                }}
              />
            )}

            <FormControl sx={{ m: 1 }}>
              <InputLabel id="demo-multiple-chip-label">Slots</InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={multiSlot}
                onChange={handleChangeMultiSlot}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {names.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, multiSlot, theme)}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1 }}>
              <InputLabel id="demo-multiple-chip-label">Spaces</InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={multiSpace}
                onChange={handleChangeMultiSpace}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                <MenuItem
                  key="51f0b555-4de6-4160-7a33-08d81366a016"
                  value="51f0b555-4de6-4160-7a33-08d81366a016"
                  style={getStyles(name, multiSpace, theme)}
                >
                  Court 1
                </MenuItem>
                <MenuItem
                  key="810f69b6-fd1e-41ec-af95-08d814e092f5"
                  value="810f69b6-fd1e-41ec-af95-08d814e092f5"
                  style={getStyles(name, multiSpace, theme)}
                >
                  Court 2
                </MenuItem>
              </Select>
            </FormControl>
            {/* <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Slot</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={slot}
                label="Slot"
                required
                onChange={handleChange}
              >
                {names.map((name) => (
                  <MenuItem value={name}>{name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth >
              <InputLabel id="demo-simple-select-label">Space</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={space}
                label="Space"
                required
                onChange={handleChangeSpace}
              >
                <MenuItem value="51f0b555-4de6-4160-7a33-08d81366a016">
                  Court 1
                </MenuItem>
                <MenuItem value="810f69b6-fd1e-41ec-af95-08d814e092f5">
                  Court 2
                </MenuItem>
              </Select>
            </FormControl> */}
            {bookingMessage.length > 0 ? (
              <Alert severity={bookingSucess ? "success" : "error"}>
                <AlertTitle>
                  {bookingMessage.map((message) => (
                    <p key={message}>{message}</p>
                  ))}
                </AlertTitle>
                {/* <code>{bookingData}</code> */}
              </Alert>
            ) : (
              ""
            )}
            {validationMessage && (
              <Alert severity="error">
                <AlertTitle>{validationMessage}</AlertTitle>
              </Alert>
            )}
            <Button
              variant="contained"
              disabled={!tokenValidated || loading}
              onClick={() => {
                onSubmit();
                //  console.log("test");
              }}
            >
              {loading ? <CircularProgress /> : "Book"}
            </Button>
          </Stack>
        </Paper>
      </Box>
    </div>
  );
}

export default App;
